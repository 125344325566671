define("discourse/plugins/discourse-presence/discourse/components/composer-presence-display", ["exports", "discourse/plugins/discourse-presence/discourse/lib/presence", "@ember/runloop", "discourse-common/utils/decorators", "@ember/object/computed", "@ember/component", "@ember/service"], function (_exports, _presence, _runloop, _decorators, _computed, _component, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    // Passed in variables
    presenceManager: (0, _service.inject)(),
    users(topicId) {
      return this.presenceManager.users(topicId);
    },
    editingUsers(topicId) {
      return this.presenceManager.editingUsers(topicId);
    },
    isReply: (0, _computed.readOnly)("model.replyingToTopic"),
    isEdit: (0, _computed.readOnly)("model.editingPost"),
    subscribe() {
      this.presenceManager.subscribe(this.get("model.topic.id"), _presence.COMPOSER_TYPE);
    },
    presenceUsers(postId, editingUsers, users, isReply, isEdit) {
      if (isEdit) {
        return editingUsers.filterBy("post_id", postId);
      } else if (isReply) {
        return users;
      }
      return [];
    },
    shouldDisplay: (0, _computed.gt)("presenceUsers.length", 0),
    typing() {
      (0, _runloop.throttle)(this, this._typing, _presence.KEEP_ALIVE_DURATION_SECONDS * 1000);
    },
    _typing() {
      if (!this.isReply && !this.isEdit || !this.get("model.composerOpened")) {
        return;
      }
      let data = {
        topicId: this.get("model.topic.id"),
        state: this.isEdit ? _presence.EDITING : _presence.REPLYING,
        whisper: this.get("model.whisper"),
        postId: this.get("model.post.id"),
        presenceStaffOnly: this.get("model._presenceStaffOnly")
      };
      this._prevPublishData = data;
      this._throttle = this.presenceManager.publish(data.topicId, data.state, data.whisper, data.postId, data.presenceStaffOnly);
    },
    cancelThrottle() {
      this._cancelThrottle();
    },
    composerState() {
      if (this._prevPublishData) {
        this.presenceManager.publish(this._prevPublishData.topicId, _presence.CLOSED, this._prevPublishData.whisper, this._prevPublishData.postId);
        this._prevPublishData = null;
      }
    },
    closeComposer() {
      this._cancelThrottle();
      this._prevPublishData = null;
      this.presenceManager.cleanUpPresence(_presence.COMPOSER_TYPE);
    },
    _cancelThrottle() {
      if (this._throttle) {
        (0, _runloop.cancel)(this._throttle);
        this._throttle = null;
      }
    }
  }, [["method", "users", [(0, _decorators.default)("model.topic.id")]], ["method", "editingUsers", [(0, _decorators.default)("model.topic.id")]], ["method", "subscribe", [(0, _decorators.on)("didInsertElement")]], ["method", "presenceUsers", [(0, _decorators.default)("model.post.id", "editingUsers.@each.last_seen", "users.@each.last_seen", "isReply", "isEdit")]], ["method", "typing", [(0, _decorators.observes)("model.reply", "model.title")]], ["method", "cancelThrottle", [(0, _decorators.observes)("model.whisper")]], ["method", "composerState", [(0, _decorators.observes)("model.action", "model.topic.id")]], ["method", "closeComposer", [(0, _decorators.on)("willDestroyElement")]]]));
});