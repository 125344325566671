define("discourse/plugins/discourse-presence/discourse/components/topic-presence-display", ["exports", "discourse-common/utils/decorators", "@ember/component", "discourse/plugins/discourse-presence/discourse/lib/presence", "@ember/object/computed", "@ember/service"], function (_exports, _decorators, _component, _presence, _computed, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    topic: null,
    topicId: null,
    presenceManager: (0, _service.inject)(),
    users(topicId) {
      return this.presenceManager.users(topicId);
    },
    shouldDisplay: (0, _computed.gt)("users.length", 0),
    didReceiveAttrs() {
      this._super(...arguments);
      if (this.topicId) {
        this.presenceManager.unsubscribe(this.topicId, _presence.TOPIC_TYPE);
      }
      this.set("topicId", this.get("topic.id"));
    },
    subscribe() {
      this.set("topicId", this.get("topic.id"));
      this.presenceManager.subscribe(this.get("topic.id"), _presence.TOPIC_TYPE);
    },
    _destroyed() {
      this.presenceManager.unsubscribe(this.get("topic.id"), _presence.TOPIC_TYPE);
    }
  }, [["method", "users", [(0, _decorators.default)("topic.id")]], ["method", "subscribe", [(0, _decorators.on)("didInsertElement")]], ["method", "_destroyed", [(0, _decorators.on)("willDestroyElement")]]]));
});